import './Screens.css'
import React, { Component, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import withProtection from '../hoc/WithProtection';
import withSingleColumnLayout from '../hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import * as d3 from 'd3';
import NPCard from '../../cards/NPCard';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment-timezone';
import NPList from '../../comp/list/NPList';
import MarketplaceAPI from '../../api/MarketplaceAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const cookies = new Cookies();

function DayUserSessionsScreen() {

    const [sessions, setSessions] = useState([])

    const location = useLocation()
    const history = useHistory()

    if (!location.state) { history.push('/admin/analytics'); }

    /**
     * Loads the sessions of the given day
     */
    const loadSessionsOfDay = async () => {

        if (!location.state) return;

        const sessionsResponse = await new MarketplaceAPI().getSessionsOfDay(moment(location.state.date).format('YYYYMMDD'))

        if (sessionsResponse && sessionsResponse.sessions) setSessions(sessionsResponse.sessions)

    }

    /**
     * Preps the data for the list
     */
    const prepData = (item) => {

        return {
            values: [
                { value: `****${item.sessionId.substring(item.sessionId.length - 6)}` },
                { value: item.date }
            ]
        }

    }

    useEffect(loadSessionsOfDay, [])

    return (
        <div className="day-user-sessions-screen screen">
            {location.state && <div className='precard-title'>User Sessions on {moment(location.state.date).format("DD MMMM YYYY")}</div>}
            <NPCard>
                <NPList
                    data={sessions}
                    types={["string", "datetime"]}
                    elementsSizing={[null, "30%"]}
                    headers={["Session Id", "Started at"]}
                    dataConverter={prepData}
                    onItemClick={(item) => { history.push(`/admin/analytics/sessions/${item.sessionId}`) }}
                />
            </NPCard>
        </div>
    )
}
function Title() {

    return (
        <div className="admin-transactions-title">
        </div>
    )
}


export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(DayUserSessionsScreen)),
        "wide"
    )
);